import { h } from "preact";
import { Router } from "preact-router";

import Header from "./header";
import Footer from "./footer";
// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Team from "../routes/team";
import Game from "../routes/game";
import Competition from "../routes/competition";
import Stages from "../routes/stages";
import { BackgroundImage } from "./icons/initial";
import DateSelector from "../routes/date-selector";
import LiveGames from "../routes/live-games";
import Search from "../routes/search";
import Favourites from "../routes/favourites";
import Player from "../routes/player";
import TeamFixtures from "../routes/team-fixtures";
import AppInfo from "../routes/app-info";

const App = () => {
  const handleRoute = () => {
    // let old = history.pushState;
    window.scrollTo({ top: 0 });
  };

  return (
    <div id="app">
      <Header />
      <BackgroundImage class={"background-image"} />
      <div className="page-wrapper">
        <Router onChange={handleRoute}>
          <Home path="/" />
          <LiveGames path="/live/" />
          <DateSelector path="/date/" />
          <DateSelector path="/date/:dateInput" />
          <Game path="/game/:gameId" />
          <Search path="/search/" />
          <Favourites path="/favourites/" />
          <Stages path="/stages/:competitionId" />
          <Competition path="/competition/:competitionId" />
          <Team path="/team/:teamId" />
          <Player path="/player/:playerId" />
          <TeamFixtures path="/team-fixtures/:teamId" />
          <AppInfo path="/info/" />
        </Router>
        <p class={"copyright"}>
          {" "}
          Copyright &copy; {`${new Date().getFullYear()}`} Nijat Bakh
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default App;
