import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.css";
import { SearchIcon, TopGames, FavouriteIcon, MainLogo, InfoIcon } from "../icons/initial";

const Footer = () => (
  <footer class={style.footer}>
    <nav>
      <ul>
        <li class={style.info_section}>
          <Link href="/" class={style.main_logo}>
            <MainLogo />
          </Link>
        </li>
        <li>
          <Link activeClassName={style.active} href="/">
            <TopGames />
            <h5>Top Games</h5>
          </Link>
        </li>
        <li>
          <Link activeClassName={style.active} href="/favourites">
            <FavouriteIcon />
            <h5>Favourites</h5>
          </Link>
        </li>
        <li>
          <Link activeClassName={style.active} href="/search">
            <SearchIcon />
            <h5>Search </h5>
          </Link>
        </li>
        <li class={style.info_section}>
          <Link activeClassName={style.active} href="/info">
            <InfoIcon style={{ fill: "white" }} />
            <h5>More Info </h5>
          </Link>
        </li>
      </ul>
    </nav>
  </footer>
);

export default Footer;
