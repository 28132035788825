import "./style";
import App from "./components/app";
import Amplify from "@aws-amplify/core";
import awsconfig from "./aws-exports";
import Cache from "@aws-amplify/cache";

Amplify.configure(awsconfig);
Cache.configure({
  itemMaxSize: 200000, // 200KB
  capacityInBytes: 2000000, //2MB
});

export default App;
