import Match, { Link } from "preact-router/match";
import style from "./style.css";
import { MainLogo, BackButton } from "../icons/initial";

const Header = () => (
  <header class={style.header}>
    <Match path="/">
      {({ matches }) =>
        !matches && (
          <div
            class={style.back_container}
            onClick={() => {
              history.back();
            }}
          >
            <BackButton />
          </div>
        )
      }
    </Match>
    <div class={style.logo_container}>
      <Link class={style.logo} href="/">
        <MainLogo />
      </Link>
    </div>
  </header>
);

export default Header;
